<template>
  <div>
    <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
      <b-card v-if="lessonDetails" class="mb-2">
        <div class="page">
          <a @click="goBack()" class="paragrafo" style="color: #145DA0">
            <feather-icon
            icon="ChevronLeftIcon"
            size="24"
          /></i>Voltar para o Súmario
          </a>
        </div> 

        <div class="activity">
          <h2 class="card-title2 mb-2">{{ lessonDetails.name }}</h2>
          <hr />
          <p class="paragrafo"><strong>Objetivo: </strong>{{ lessonDetails.objective }}</p>
          <p class="paragrafo">
            <strong>Módulo: </strong>{{ lessonDetails.module && lessonDetails.module.name }}
          </p>
          <p class="paragrafo">
            <strong>Competências: </strong>
            <span :key="indextrc" v-for="(tr_competence, indextrc) in lessonDetails.competences">
              {{ tr_competence.code }},
            </span>
          </p>
          <p class="paragrafo">
            <strong>Habilidades: </strong><span :key="indextra"
              v-for="(tr_abilities, indextra) in lessonDetails.abilities">
              {{ tr_abilities.code }},
            </span>
          </p>
          <hr />
          <h2 class="card-title2 small">Atividades: </h2>
          <hr />
          <b-container fluid :key="indextr2" v-for="(tr2, indextr2) in lessonDetails.activities">
            <b-row>
              <b-col class="text-left" style="height: 85px;">
                <span class="icon-container"><img :src="tr2.phase.icon" width="50" height="50" /></span>
                <span class="gray-line">{{ tr2.phase.name }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div v-html="tr2.description"></div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div v-show="tr2.support_materials.length > 0" class="text-center" colspan="2" style="width: 100%">
                  <p><strong>Materiais de apoio: </strong></p>
                  <b-row>
                    <b-col lg="2" :key="indextr3"  v-for="(tr3, indextr3) in tr2.support_materials">
                      <b-link :href="tr3.url" target="_blank">
                        <b-img
                          fluid
                          :src="require('@/assets/images/pages/files.svg')"
                        />
                      </b-link>
                    </b-col>
                  </b-row>
                  <!--
                  <b-table-simple class="responsive text-left" id="tableMaterialsBook">
                    <b-tr>
                      <b-th colspan="2">Materiais de apoio:</b-th>
                    </b-tr>
                    <b-tr :key="indextr3" v-for="(tr3, indextr3) in tr2.support_materials">
                      <b-td class="text-left">
                        <a :href="tr3.url" target="_blank">{{ tr3.name }}</a>
                      </b-td>
                    </b-tr>
                  </b-table-simple> -->
                </div>
              </b-col>
            </b-row>
            <hr />
          </b-container>
          <div v-show="lessonDetails.support_materials.length > 0">
            <p><strong>Materiais de apoio da aula: </strong></p>
            <b-row>
              <b-col lg="2" :key="indextr3" v-for="(tr3, indextr3) in lessonDetails.support_materials">
                <b-link :href="tr3.url" target="_blank">
                  <b-img
                    fluid
                    :src="require('@/assets/images/pages/files.svg')"
                  />
                </b-link>
              </b-col>
            </b-row>
            <!--<b-table-simple class="responsive text-left" id="tableMaterialsBook">
              <b-tr >
                
                <b-td class="text-left">
                  <a :href="tr3.url" target="_blank">{{ tr3.name }}</a>
                </b-td>
              </b-tr>
            </b-table-simple> -->
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import noData from "@/components/systemWarning/NoData.vue";

export default {
  components: {
    BCard,
    noData,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      lessonDetails: null,
    };
  },
  computed: {},
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    showLesson() {
      this.isLoading = true;
      this.$store
        .dispatch("lessons/fetchDetails", {
          lesson_id: this.$route.params.lesson_id,
          paginate: false,
        })
        .then((response) => {
          this.lessonDetails = response;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.showLesson();
  },
};
</script>
<style>
.activity {
  padding: 1.5rem 1.5rem
}

.gray-line {
  background-color: #090909;
  color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-transform: uppercase;
  display: inline-flex;
  height: 40px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -22.5px;
  left: -8px;
  font-size: 0.7rem;
}

.icon-container {
  background-color: #090909;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.card-title2 {
  align-items: flex-start;
  color: #212121;
  display: flex;
  font-family: Montserrat,sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 150%;
  font-style: normal;
  margin-bottom: 2px;
  margin-top: 32px;
  padding: 0;
}

.card-title2.small{
  font-size: 24px;
}
.paragrafo{
  color: #212121;
  font-size: 16px;
  font-style: normal;
  text-align: justify;
  line-height: 150%;
  font-weight: 400;
  scroll-margin: 60px;
  margin: 16px 0;
}

strong {
  font-size: 1.286rem;
}
</style>
